.pro-sidebar-content{
    background: linear-gradient(180deg,#135e7e,#103b5b 35%,#015b91);
}

.MenuItem{
    color:black !important;
}

.first{
    color:white !important;
}


.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 999999999;
    left: 245px;
    margin-top: -30px;
}

.tooltips:hover .tooltiptext {
    visibility: visible;
}

.tooltips{
    position: absolute;
    margin-top: -10px;
    z-index: 1007;
}

/* The Modal (background) */
.modal-tooltip {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999999999999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    left: 0;
}

/* Modal Header */
.modal-header-tooltip {
    padding: 2px 16px;
    background: #015b91;
    color: white;
}

/* Modal Body */
.modal-body-tooltip {padding: 2px 16px;}

/* Modal Footer */
.modal-footer-tooltip {
    padding: 2px 16px;
    background: #015b91;
    color: white;
}

/* Modal Content */
.modal-content-tooltip {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.4s;
    left: 5%;
}

/* Add Animation */
@keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
}

.btn-primary{
    background-color: #015b91;
    border-color: #015b91;
    color:white;
}

.tooltips .material-icons {
    color: #015b91;
}

.pro-sidebar .pro-menu .pro-menu-item.active{
    background-color: #a2d9f7;
    border-color: #a2d9f7;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #a2d9f7;
}
.pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item:hover{
    color: #015b91;
}
.col .ga-item img, .ga-item img {
    object-fit: scale-down;
}

.colMax{
    max-width: 270px !important;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #135e7e;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #a2d9f7;
}

.react-page-editable .react-page-cell-insert-new {
    cursor: cell;
    flex: 1 1;
    min-height: 100px;
    transition: all .4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    z-index: 1;
    position: relative;
}

.react-page-editable .react-page-cell-insert-new-icon {
    align-items: center;
    background-color: rgba(0,0,0,.26);
    background-color: rgba(0,0,0,.26);
    border-radius: 50%;
    display: flex;
    height: 52px;
    justify-content: center;
    width: 52px;
}
.react-page-editable .react-page-cell-insert-new-icon svg {
    height: 24px;
    fill: #fff;
}

.react-page-editable .react-page-cell-insert-new:hover {
    background: rgba(0,0,0,.26);
    background: rgba(0,0,0,.26);
    border: 1px solid hsla(0,0%,100%,.54);
    border: 1px solid hsla(0,0%,100%,.54);
}

.moduleNav{
    display:none;
    margin-top: 0px;
    height: calc(100vh - 0px);
    position: fixed;
    left: 0;
    z-index: 1010;
    top: 0;
}


.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    overflow-y: hidden;
}

.pro-sidebar-content{
    overflow-y: scroll;
}

.theme-footer p,.theme-header p{
    margin-right: 15px;
}



/*.sun-editor .se-toolbar{
    position: fixed !important;
    bottom: 65px !important;
    right: 80px;
    top: unset !important;
    z-index: 999999999999999999999;
}*/

.formControl{
    padding-bottom: 40px;
    background-color: white;
}
.settingsWebBuilderModul {
    position: fixed;
    z-index: 99999999999999;
    background: white;
    border: 1px solid black !important;
    width: 18%;
    left: 10px;
    top: 70px;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    max-height: 60%;
    overflow: scroll;
}



#Teaser{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    transition: opacity .4s ease;
    opacity: 0;
    background: hsla(0,0%,100%,.95);
    text-align: center;
    color: rgba(0,0,0,.97);
    display: inline-block;
    padding: 12px 24px;
    margin: 0 auto;
    border-radius: 12px 12px 0 0;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: .15em;
    box-shadow: 0 -5px 5px rgb(0 0 0 / 22%);
    pointer-events: none;
}

.vmiddle{
    vertical-align: middle;
}

.bgunset {
    background: unset;
}


